<template>
  <div class="content-wrapper">
    <bo-page-title :no-add="true" />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row class="justify-content-end">
            <div class="col-lg-4">
                <div class="mr-2 form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                    <input class="form-control"
                        v-model="filter.search"
                        @input="doSearch"
                        placeholder="Ketik Kelas/Bangsal"
                    />

                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                        
                    <b-button
                        class="ml-1 d-inline-flex align-items-center"
                        variant="outline-success"
                        id="resetBtn"
                        @click="doResetData()"
                    >Reset</b-button>
                </div>
            </div>
            <div class="col-lg-2">
              <b-button @click="apiGet()" v-b-tooltip.hover.noninteractive="'Muat ulang'" variant="primary">
                  <i class="icon-spinner11"></i>
                </b-button>
            </div>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Data {{ pageTitle }} Tidak Tersedia</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>

            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'

const _ = global._

export default {
  extends: GlobalVue,
  data() {
    return {
        fields: [
            {
                key: 'number',
                label: '#',
            },
            {
                key: 'mrankel_name',
                label: 'Kelas',
            },
            {
                key: 'mranb_name',
                label: 'Bangsal',
            },
            {
                key: 'bed_total',
                label: 'Jumlah Bed',
            },
            {
                key: 'bed_used',
                label: 'BED Terpakai',
            },
            {
                key: 'bed_available',
                label: 'BED Tersedia',
            },
        ],
    }
  },
  mounted(){
    this.apiGet()
    this.sockets.subscribe('disp_refresh_data_bed', () => {
      this.apiGet()
    })
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    doResetData(){
      this.doReset()
    },
    doSearch: _.debounce(function(){
      this.doFilter()
    },1000),
  },
  destroyed(){
    this.sockets.unsubscribe('disp_refresh_data_bed')
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}
</script>